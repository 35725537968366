import React from "react"
import VideoModal from "../video-modal"
import welcomeMobile from "../../images/welcome-mobile.svg"
import welcome from "../../images/welcome.svg"
import { LazyLoadImage } from "react-lazy-load-image-component"

import "./hero-adjusted.scss"

const Hero = ({
  children,
  customPlayButton,
  videoImg,
  pageTitle,
  location,
  style,
  scaled,
}) => {
  //   console.log(pageTitle)

  return (
    <div
      className={`hero-adjustment pb-md-0 ${
        scaled !== undefined && scaled === true ? `hero-video-scaled` : null
      }
                `}
    >
      <div className="container">
        <div className="hero-content-wrapper" style={{ position: "relative" }}>
          <div className="row no-gutters elbow-dysplasia-video-row">
            <div className="col-12 col-md-7">
              <div className="hero-dog-bg">
                <div className="dog-bg"></div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="hero-video elbow-dysplasia-video">
                <VideoModal
                  customPlayButton={customPlayButton}
                  location={location}
                  videoImg={videoImg}
                />
              </div>
            </div>
          </div>
          {pageTitle !== undefined ? (
            <div
              className={`inner-page-title total-hip-hero medial-patella-hero elbow-dysplasia-hero ${
                videoImg ? videoImg : "no-video-class"
              }`}
              dangerouslySetInnerHTML={{ __html: `<h1>${pageTitle}</h1>` }}
            />
          ) : (
            <div>
              <div className="welcome-img d-none d-md-block">
                <LazyLoadImage
                  effect="blur"
                  alt="welcome"
                  src={welcome}
                  className="img-fluid"
                />
              </div>
              <div className="welcome-mobile-img d-block d-md-none">
                <LazyLoadImage
                  effect="blur"
                  alt="welcome"
                  src={welcomeMobile}
                  className="img-fluid"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Hero
