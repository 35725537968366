import React from "react"
import { Modal, Button } from "react-bootstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

class VideoModal extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleNext = this.handleNext.bind(this)

    this.state = {
      show: false,
      iframeVideo2: false,
      iframeVideo1: true,
    }
  }

  handleClose() {
    this.setState({ show: false })
  }
  handleNext() {
    this.setState({ iframeVideo2: !this.state.iframeVideo2 })
    this.setState({ iframeVideo1: !this.state.iframeVideo1 })
  }

  handleShow() {
    this.setState({ show: true })
  }

  render() {
    // console.log(this.props.location.pathname)
    const iframe = [
      {
        id: 0,
        url: "/acl-tears/",
        iframeSrc: "https://player.vimeo.com/video/440718705",
        width: "395px",
      },
      {
        id: 1,
        url: "/hip-dysplasia/",
        iframeSrc: "https://player.vimeo.com/video/440730449",
        width: "395px",
      },
      {
        id: 2,
        url: "/",
        iframeSrc: "https://player.vimeo.com/video/440735813",
        width: "395px",
      },
      {
        id: 3,
        url: "/medial-patella-luxation/",
        iframeSrc: "https://player.vimeo.com/video/440736682",
        iframeSrc2: "https://player.vimeo.com/video/489028503",
        width: "395px",
      },
      {
        id: 5,
        url: "/total-hip-replacement/",
        iframeSrc: "https://player.vimeo.com/video/440738249",
        width: "395px",
      },
      {
        id: 6,
        url: "/tplo/",
        iframeSrc: "https://player.vimeo.com/video/440744249",
        iframeSrc2: "https://player.vimeo.com/video/452696047",
        width: "395px",
      },
      {
        id: 7,
        url: "/elbow-dysplasia/",
        iframeSrc: "https://player.vimeo.com/video/440688369",
        width: "395px",
      },
      {
        id: 8,
        url: "/fractures-dislocations/",
        iframeSrc: "https://player.vimeo.com/video/489038576",
        width: "395px",
      },
      {
        id: 9,
        url: "/canine-shoulder-disorders/",
        iframeSrc: "https://player.vimeo.com/video/489037339",
      },
      {
        id: 10,
        url: "/arthroscopy/",
        iframeSrc: "https://player.vimeo.com/video/489039914",
        width: "395px",
      },
      {
        id: 11,
        url: "/reviews/",
        iframeSrc: "https://player.vimeo.com/video/489040968",
        width: "395px",
      },
      {
        id: 12,
        url: "/disc/",
        iframeSrc: "https://player.vimeo.com/video/515942026",
        width: "395px",
      },
    ]
    const pageIframeHero = iframe.filter(
      (item) => item.url === this.props.location.pathname
    )
    // console.log(pageIframeHero.length)
    return (
      <div>
        <a
          onClick={this.handleShow}
          onKeyDown={this.handleShow}
          className="custom-link"
        />
        <div className="video-modal-btn d-flex justify-content-center">
          {this.props.customPlayButton ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{
                alignSelf: "center",
                position: "absolute",
                zIndex: 1,
                width: "50px",
                height: "50px",
              }}
            >
              <path
                fill="#d3d3d3"
                d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"
              />
            </svg>
          ) : null}
          {this.props.videoImg ? (
            <LazyLoadImage
              effect="blur"
              src={this.props.videoImg}
              onClick={this.handleShow}
              onKeyDown={this.handleShow}
              alt="video img"
              className="video-img img-fluid"
              width="100%"
              height="100%"
            />
          ) : null}
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          dialogClassName="modal-600 video-wrap-img"
          centered
        >
          <Modal.Body>
            <Button
              className="close-btn"
              title="Close"
              onClick={this.handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512.001 512.001"
                width="10"
                height="10"
                fill="#fff"
              >
                <path d="M284.286 256.002L506.143 34.144c7.811-7.811 7.811-20.475 0-28.285-7.811-7.81-20.475-7.811-28.285 0L256 227.717 34.143 5.859c-7.811-7.811-20.475-7.811-28.285 0-7.81 7.811-7.811 20.475 0 28.285l221.857 221.857L5.858 477.859c-7.811 7.811-7.811 20.475 0 28.285a19.938 19.938 0 0014.143 5.857 19.94 19.94 0 0014.143-5.857L256 284.287l221.857 221.857c3.905 3.905 9.024 5.857 14.143 5.857s10.237-1.952 14.143-5.857c7.811-7.811 7.811-20.475 0-28.285L284.286 256.002z" />
              </svg>
            </Button>
            <div className="text-center">
              <div
                className=""
                style={{ height: "395px", backgroundColor: "#000" }}
              >
                {pageIframeHero.length !== 0 ? (
                  pageIframeHero[0].iframeSrc2 ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 490.8 490.8"
                        onClick={this.handleNext}
                        className="fa fa-angle-right nextHeroModal p-2"
                        width="10"
                        height="10"
                        fill="#fff"
                      >
                        <path
                          d="M135.685 3.128c-4.237-4.093-10.99-3.975-15.083.262-3.992 4.134-3.992 10.687 0 14.82l227.115 227.136-227.136 227.115c-4.237 4.093-4.354 10.845-.262 15.083 4.093 4.237 10.845 4.354 15.083.262.089-.086.176-.173.262-.262l234.667-234.667c4.164-4.165 4.164-10.917 0-15.083L135.685 3.128z"
                          fill="#f44336"
                        />
                        <path d="M128.133 490.68a10.667 10.667 0 01-7.552-18.219l227.136-227.115L120.581 18.232c-4.171-4.171-4.171-10.933 0-15.104 4.171-4.171 10.933-4.171 15.104 0l234.667 234.667c4.164 4.165 4.164 10.917 0 15.083L135.685 487.544a10.663 10.663 0 01-7.552 3.136z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 443.52 443.52"
                        onClick={this.handleNext}
                        className="fa fa-angle-right prevHeroModal p-2"
                        width="10"
                        height="10"
                        fill="#fff"
                      >
                        <path d="M143.492 221.863L336.226 29.129c6.663-6.664 6.663-17.468 0-24.132-6.665-6.662-17.468-6.662-24.132 0l-204.8 204.8c-6.662 6.664-6.662 17.468 0 24.132l204.8 204.8c6.78 6.548 17.584 6.36 24.132-.42 6.387-6.614 6.387-17.099 0-23.712L143.492 221.863z" />
                      </svg>
                    </>
                  ) : null
                ) : null}
                {pageIframeHero.length !== 0 ? (
                  <>
                    {this.state.iframeVideo1 ? (
                      <iframe
                        src={pageIframeHero[0].iframeSrc}
                        style={{ width: "100%", height: "inherit" }}
                        frameBorder="0"
                        allow="fullscreen"
                        allowFullScreen
                        title="Hero videos"
                      ></iframe>
                    ) : null}
                    {pageIframeHero[0].iframeSrc2 && this.state.iframeVideo2 ? (
                      <iframe
                        src={pageIframeHero[0].iframeSrc2}
                        style={{ width: "100%", height: "inherit" }}
                        frameBorder="0"
                        allow="fullscreen"
                        allowFullScreen
                        title="Hero videos"
                      ></iframe>
                    ) : null}
                  </>
                ) : (
                  <LazyLoadImage
                    effect="blur"
                    onClick={this.handleShow}
                    src={this.props.videoImg}
                    alt="video"
                    className="w-100"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default VideoModal
